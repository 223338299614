import React, { useState, useEffect } from "react";
import LoadingSpinner from "../Components/LoadingSpinner";
import classes from "./FAQ.module.css";

import TimesBreadCrumbs from "../Components/TimesBreadCrumbs";

// import { useHistory } from "react-router-dom";

import { Link } from "react-router-dom/cjs/react-router-dom.min";

import Modal from "../Components/Modal";
const FAQ = () => {
  const [data, setData] = useState([]);
  const [isLoading, setLoading] = useState(false);
  const [HomeYouTubeVideos, setHomeYouTubeVideos] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  // const [startDate, setStartDate] = useState("");
  // const [endDate, setEndDate] = useState("");
  // const [errors, setErrors] = useState(false);
  const startDate = ""
  const endDate = ""
  const [modalShow, setModalShow] = useState(false);

  // const history = useHistory();

  const HomeYouTubeVideosApi = () => {
    setLoading(true);
    var myHeaders = new Headers();
    myHeaders.append(
      "Authorization",
      "Token 4YzpGk8Vf8IcxzxQ68teZ2KM5iX8SktW5RN4iz92"
    );
    myHeaders.append("Content-Type", "application/json");

    var raw = JSON.stringify({
      StartDate: startDate || date,
      EndDate:
        endDate ||
        new Date()
          .toLocaleDateString("in", {
            day: "2-digit",
            year: "numeric",
            month: "2-digit",
          })
          .split("/")
          .reverse()
          .join("-"),
      page: currentPage,
    });

    var requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    };
    fetch(
      `${process.env.REACT_APP_API_URL}/adminapi/GetWealthFAQ`,
      requestOptions
    )
      .then((response) => response.json())
      .then((result) => {
        if (result.status_code === 200) {
          setHomeYouTubeVideos(result.data);
          console.log(result, "test");
          setData(result.data);
        }
      })
      .catch((error) => console.log("error", error))
      .finally(() => setLoading(false));
  };

  useEffect(() => {
    HomeYouTubeVideosApi();
  }, [currentPage]);

  const DeleteApi = (Id) => {
    var myHeaders = new Headers();
    myHeaders.append(
      "Authorization",
      "Token 4YzpGk8Vf8IcxzxQ68teZ2KM5iX8SktW5RN4iz92"
    );
    myHeaders.append("Content-Type", "application/json");

    var raw = JSON.stringify({
      Id: Id,
    });

    var requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    };

    fetch(
      `${process.env.REACT_APP_API_URL}/adminapi/DeleteWealthFAQ`,
      requestOptions
    )
      .then((response) => response.json())
      .then((result) => {
        if (result.status_code === 200) {
          HomeYouTubeVideosApi();
          setModalShow(true);
        }
      })
      .catch((error) => console.log("error", error));
  };

  let today = new Date();
  today.setDate(today.getDate() - 60);
  let date = new Date(today)
    .toLocaleDateString("in", {
      day: "2-digit",
      year: "numeric",
      month: "2-digit",
    })
    .split("/")
    .reverse()
    .join("-");

  const pageInfo = {
    currentPageName: "FAQ List",
  };
  return (
    <>
      {modalShow && (
        <Modal
          heading={"Deleted successfully"}
          onClick={() => {
            setModalShow(false);
          }}
        />
      )}

      <div className={classes.dashboard_container}>
        <div className={classes.TimsbiBreadcrumb}>
          <TimesBreadCrumbs pageInfo={pageInfo} />{" "}
        </div>
        <div className={classes.DateFilter_container}>
          <div>
            <button className={classes.Addbtn}>
              <Link to="/BiDashboard/FaqInsert" className={classes.Edit_link}>
                Add FAQ
              </Link>
            </button>
          </div>
        </div>

        <div className={classes.table_container}>
          <div className={classes.buttons1}>
            <div>
              <button
                onClick={() => {
                  setCurrentPage(currentPage - 1);
                }}
                className={classes.buttonone}
              >
                Prev
              </button>

              <button
                onClick={() => {
                  setCurrentPage(currentPage + 1);
                }}
                className={classes.buttonone}
              >
                Next
              </button>
            </div>
          </div>
          <div className={classes.container}>
            {isLoading ? (
              <div className={classes.spinner}>
                <LoadingSpinner />{" "}
              </div>
            ) : data.length ? (
              <>
                <table className={classes.orders_table}>
                  <tr className={classes.table_row}>
                    <th className={classes.table_heading}>Sr. No.</th>
                    <th className={classes.table_heading}>Question</th>
                    <th className={classes.table_heading}>Answer</th>
                    <th className={classes.table_heading}>Date</th>
                    <th className={classes.table_heading}>Time</th>

                    <th className={classes.table_heading}>Delete</th>
                    <th className={classes.table_heading}>Update</th>
                  </tr>


                  <tbody>
                    {HomeYouTubeVideos.map((item, i) => {
                      return (
                        <tr className={classes.table_row}>
                          <td className={classes.table_td}>{i + 1}</td>
                          <td className={classes.table_td}>{item.Question}</td>
                          <td className={classes.table_td}>{item.Answer}</td>
                          <td className={classes.table_td}>
                            {item.CreatedAt.slice(0, 10)}
                          </td>
                          <td className={classes.table_td}>
                            {item.CreatedAt.slice(11, 16)}
                          </td>

                          <td className={classes.table_td}>
                            <button
                              className={classes.delbtn}
                              onClick={() => DeleteApi(item.Id)}
                            >
                              Delete
                            </button>

                          </td>
                          <td className={classes.table_td}>
                            <Link
                              to={"/BiDashboard/UpdateFaq/" + item.Id}
                              className={classes.Edit_link_two}
                            >
                              Edit
                            </Link>
                          </td>



                        </tr>
                      );
                    })}
                  </tbody>
                </table>
              </>
            ) : (
              <h2 className={classes.serachErrorText}>"No Data found !"</h2>
            )}
          </div>
        </div>
      </div>
    </>
  );
};

export default FAQ;
